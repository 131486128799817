<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework user roles</h3>

	<p><span v-html="app_name"/> users can <span v-html="link('framework_index', 'view')"></span> <b>public</b> frameworks, and <span v-html="link('treeview', 'browse')"></span>, <span v-html="link('search', 'search')"></span>, <span v-html="link('pinning', 'pin')"></span>, and <span v-html="link('export_options', 'print')"></span> {{comp_noun+'s'}} for these frameworks, <b>without signing in to <span v-html="app_name"/></b>.</p>
	<p>Users with registered <span v-html="app_name"/> accounts can also be assigned <span v-html="link('roles_system', 'system-level rights')"></span> to review private frameworks, edit frameworks, and administer other users’ rights.</p>
	<p>Alternatively, users can be assigned rights for any <i>one particular framework</i>:</p>
	<ul>
		<li>For a <b>private</b> framework:<ul>
			<li>Users with default rights (that is, users who have not been granted any of the roles described below) <i>cannot view the framework.</i></li>
			<li>Users with <b>Framework Reviewer</b> rights can can view and make <span v-html="link('add_comments', 'comments')"></span> on items in the framework, and contribute to comment groups they’ve been added to.</li>
			<li>Users with <b>Framework Editor</b> rights can <i>also</i> <i><span v-html="link('editing', 'edit')"></span></i> the framework.</li>
			<li>Users with <b>Framework Admin</b> rights can <i>also</i> <i><span v-html="link('user_roles_admin', 'grant rights')"></span></i> to other users for the framework.</li>
		</ul></li>
	</ul>
	<ul>
		<li>For a <b>public</b> framework:<ul>
			<li>Users with <b>Default</b> rights (that is, users who have not been granted any of the roles described below) can view and make <span v-html="link('add_comments', 'comments')"></span> on items in the framework, and contribute to comment groups they’ve been added to. (So there is no need for a separate “reviewer” level of rights for public frameworks.)</li>
			<li>Users with <b>Framework Editor</b> rights can <i>also</i> <i><span v-html="link('editing', 'edit')"></span></i> the framework.</li>
			<li>Users with <b>Framework Admin</b> rights can <i>also</i> <i><span v-html="link('user_roles_admin', 'grant rights')"></span></i> to other users for the framework.</li>
		</ul></li>
	</ul>
	<p><b>Note</b> that users with an asterisk (*) after their Framework Rights in the “Manage User Rights for Framework” interface have been granted the displayed rights at the system level (i.e. for all frameworks). You can “upgrade” a user to give them a higher rights level for a framework than they have at the system level, but you cannot “downgrade” a user to reduce their rights for a framework.</p>

	<hr>
	
	<h3 class="mt-2">Managing framework roles</h3>

	<p>Users with <b>System Admin</b> rights, or users with <b>Framework Admin</b> rights to a particular framework, can grant and manage rights for other users to that framework via the “<v-icon small>fas fa-users</v-icon> Manage user rights for framework…” item in the framework “<v-icon small>fas fa-ellipsis-v</v-icon> kebab” menu.</p>

	<p>Check out the <span v-html="link('manage_users', 'Manage Users')"></span> guide to learn more about creating or editing user access to the system.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	